export class BalanceEntry {
    constructor(timestamp, total) {
      this.timestamp = timestamp;
      this.total = total;
    }
  
    // Method to convert the Unix timestamp to a readable date format
    getFormattedTimestamp() {
      const date = new Date(this.timestamp * 1000); // Convert to milliseconds
      return date.toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }
  }