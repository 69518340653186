import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { supabase } from "./supabase";
import Dashboard from "./Dashboard";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(null);

  const handleLogin = async () => {
    setError(null);
    setLoading(true);
    setMessage("");

    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    setLoading(false);

    if (error) {
      setError(error.message);
    } else {
      setMessage("Login successful!");
      window.location.reload();
    }
  };

  const handleLogout = async () => {
    setError(null);
    setLoading(true);
    setMessage("");

    const { error } = await supabase.auth.signOut();

    setLoading(false);

    if (error) {
      setError(error.message);
    } else {
      setMessage("Logged out!");
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        supabase.auth.onAuthStateChange((event, session) => {
          switch (event) {
            case "SIGNED_IN":
              setUser(session?.user);
              break;
            case "SIGNED_OUT":
              setUser(null);
              break;
            default:
          }
        });
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };

    fetchSession();
  }, []);

  return (
    <>
      {user ? (
        <Container
          variant="contained"
          sx={{
            backgroundColor: "#121212",
            minWidth: "100%",
            minHeight: "100vh",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Container
            variant="contained"
            sx={{
              backgroundColor: "#121212",
              minWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Dashboard />
          </Container>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogout}
            disabled={loading}
            sx={{ width: "100%", justifySelf: "center", marginTop: "10%" }}
          >
            Logout
          </Button>
        </Container>
      ) : (
        <Container
          sx={{
            backgroundColor: "#121212",
            minWidth: "100%",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <Paper
            sx={{
              padding: 3,
              backgroundColor: "#1e1e1e",
              borderRadius: 2,
              boxShadow: 3,
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{ style: { color: "white" } }}
                InputLabelProps={{ style: { color: "gray" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "gray",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />

              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{ style: { color: "white" } }}
                InputLabelProps={{ style: { color: "gray" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "gray",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={loading}
                sx={{ padding: 1.5 }}
              >
                {loading ? "Logging in..." : "Login"}
              </Button>

              {error && (
                <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
                  {error}
                </Typography>
              )}
              {message && (
                <Typography
                  variant="body2"
                  color="success.main"
                  sx={{ marginTop: 2 }}
                >
                  {message}
                </Typography>
              )}
            </Box>
          </Paper>
        </Container>
      )}
    </>
  );
}
