import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Typography, Box, Paper } from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Log } from "./Log";
import BalanceChart from "./BalanceChart";
import { BalanceEntry } from "./BalanceEntry";

const Dashboard = () => {
  const [logs, setLogs] = useState([]);
  const [balance, setBalance] = useState({});
  const [balanceEntries, setBalanceEntries] = useState([])
  const [botStatus, setBotStatus] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchLogs();
    fetchBalanceHistory();
    fetchBalance();
    fetchBotStatus();

    const intervalBotStatus = setInterval(() => {
      fetchBotStatus();
    }, 120000);
  
    const intervalLogsAndBalance = setInterval(() => {
      fetchLogs();
      fetchBalanceHistory();
      fetchBalance();
    }, 60000);
  
    return () => {
      clearInterval(intervalBotStatus);
      clearInterval(intervalLogsAndBalance);
    };
  }, []);

  const fetchBalance = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BOT_URL + "/get_balance",
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_BOT_API_KEY,
          },
        }
      );
      setBalance(response.data.balance); // Die Antwort in den Status umwandeln
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const fetchBalanceHistory = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BOT_URL + "/get_balance_history",
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_BOT_API_KEY,
          },
        }
      );
      const balanceEntries = response.data.map((balanceEntry) => {
        // Parse the log string and create a new Log object
        const parsedEntry = JSON.parse(balanceEntry); // Parse the JSON string into an object
        return new BalanceEntry(
          parsedEntry.timestamp,
          parsedEntry.total
        );
      });
      // Filter out duplicates by checking the timestamp
      setBalanceEntries((prevEntries) => {
        // Create a Set of timestamps from the previous logs
        const existingTimestamps = new Set(
          prevEntries.map((entry) => entry.timestamp)
        );

        // Filter new logs to exclude any with duplicate timestamps
        const uniqueEntries = balanceEntries.filter(
          (entry) => !existingTimestamps.has(entry.timestamp)
        );

        return [...prevEntries, ...uniqueEntries];
      });
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  const fetchLogs = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BOT_URL + "/get_logs",
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_BOT_API_KEY,
          },
        }
      );
      const logObjects = response.data.map((log) => {
        // Parse the log string and create a new Log object
        const parsedLog = JSON.parse(log); // Parse the JSON string into an object
        return new Log(
          parsedLog.timestamp,
          parsedLog.level,
          parsedLog.message,
          parsedLog.color
        );
      });

      setLogs((prevLogs) => {
        const existingTimestamps = new Set(
          prevLogs.map((log) => log.timestamp)
        );

        const uniqueLogs = logObjects.filter(
          (log) => !existingTimestamps.has(log.timestamp)
        );

        return [...prevLogs, ...uniqueLogs];
      });
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  const fetchBotStatus = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BOT_URL + "/get_status",
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_BOT_API_KEY,
          },
        }
      );
      setBotStatus(response.data.is_running);
    } catch (error) {
      console.error("Error fetching bot status:", error);
    }
  };

  const handleStartBot = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_BOT_URL + "/start_bot",
        {},
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_BOT_API_KEY,
          },
        }
      );
      setBotStatus(true);
      fetchLogs();
      fetchBalanceHistory();
      fetchBalance();
    } catch (error) {
      console.error("Error starting bot:", error);
    }
  };

  const handleStopBot = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_BOT_URL + "/stop_bot",
        {},
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_BOT_API_KEY,
          },
        }
      );
      setBotStatus(false);
      fetchLogs();
      fetchBalanceHistory();
      fetchBalance();
    } catch (error) {
      console.error("Error stopping bot:", error);
    }
  };

  return (
    <>
      <img
        src={require("./assets/Bitcoin-Logo.png")}
        style={{ maxWidth: "40%" }}
        alt="Logo"
      />
      ;
      <Paper
        sx={{
          padding: isMobile ? 2 : 3,
          backgroundColor: "#1e1e1e",
          borderRadius: 2,
          boxShadow: 3,
          mb: 3,
          width: "100%",
        }}
        variant="contained"
      >
        <Typography variant="h5" color="white" gutterBottom>
          Bot Status
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" color="white">
            The bot is currently{" "}
            {botStatus ? (
              <span style={{ color: "green" }}>RUNNING</span>
            ) : (
              <span style={{ color: "red" }}>STOPPED</span>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Button
            variant="contained"
            onClick={handleStartBot}
            disabled={botStatus}
            sx={{
              flex: 1,
              backgroundColor: "#64d185", // Custom background color
              "&:hover": {
                backgroundColor: "#64d185", // Optional hover effect
              },
            }}
          >
            Start Bot
          </Button>
          <Button
            variant="contained"
            onClick={handleStopBot}
            disabled={!botStatus}
            sx={{
              flex: 1,
              backgroundColor: "#CE796B", // Custom background color
              "&:hover": {
                backgroundColor: "#CE796B", // Optional hover effect
              },
            }}
          >
            Stop Bot
          </Button>
        </Box>
      </Paper>
      <Paper
        sx={{
          padding: isMobile ? 2 : 3,
          backgroundColor: "#1e1e1e",
          borderRadius: 2,
          boxShadow: 3,
          mb: 3,
          width: "100%",
        }}
      >
        <Typography variant="h5" color="white" gutterBottom>
          Balance
        </Typography>
        <Box
          sx={{
            mb: 2,
            p: 2, // Add padding to the Box
            borderRadius: 2, // Rounded corners
            backgroundColor: "#333", // Dark background color for contrast
            border: "1px solid #444", // Subtle border for the Box
            boxShadow: 3, // Add subtle shadow for depth
            display: "flex",
            flexDirection: "column",
            gap: 1, // Space between the Typography elements
          }}
        >
          <Typography
            variant="h6"
            color="#64d185"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoOutlined sx={{ mr: 1 }} /> Free:
            </Box>
            <strong>{(balance?.free ?? 0).toFixed(2)} $</strong>
          </Typography>
          <Typography
            variant="h6"
            color="#466995"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoOutlined sx={{ mr: 1 }} /> Used:
            </Box>
            <strong>{(balance?.used ?? 0).toFixed(2)} $</strong>
          </Typography>
          <Typography
            variant="h6"
            color="#EB6534"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoOutlined sx={{ mr: 1 }} /> Total:
            </Box>
            <strong>{(balance?.total ?? 0).toFixed(2)} $</strong>
          </Typography>
        </Box>
      </Paper>
      <Paper
        sx={{
          padding: 3,
          backgroundColor: "#1e1e1e",
          borderRadius: 2,
          boxShadow: 3,
          mb: 3,
          width: "100%",
          overflow: "auto", // Ensures content is scrollable if it's too large
        }}
      >
        <Typography variant="h5" color="white" gutterBottom>
          Total Balance History
        </Typography>
        <BalanceChart balanceData={balanceEntries} />
      </Paper>
      <Paper
        sx={{
          backgroundColor: "#1e1e1e",
          borderRadius: 2,
          boxShadow: 3,
          padding: 3,
          width: "100%",
        }}
      >
        <Typography variant="h5" color="white" gutterBottom>
          Bot Logs
        </Typography>
        <Box
          sx={{
            maxHeight: 300,
            overflowY: "auto",
            backgroundColor: "#121212",
            padding: 2,
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          {logs.length > 0 ? (
            logs.map((log, index) => (
              <Typography key={index} variant="body2" color="white">
                <div
                  key={index}
                  style={{ color: log.color ? log.color : log.getLogColor() }}
                >
                  <span style={{ color: "white", marginRight: "1%" }}>
                    [{log.getFormattedTimestamp()}]
                  </span>
                  {log.level}: {log.message}
                </div>
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="gray">
              No logs available.
            </Typography>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default Dashboard;
