export const LogTypes = {
    INFO: 'INFO',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING'
  };
  
  // Mapping von Log-Typen zu Farben
  export const logTypeColors = {
    [LogTypes.INFO]: 'gray',       // INFO wird in Grau dargestellt
    [LogTypes.ERROR]: 'red',       // ERROR wird in Rot dargestellt
    [LogTypes.SUCCESS]: 'green',   // SUCCESS wird in Grün dargestellt
    [LogTypes.WARNING]: 'yellow',  // WARNING wird in Gelb dargestellt
  };
  
  // Hilfsfunktion zur Farbgebung des Logs basierend auf dem Typ
  export const getLogColor = (logType) => {
    return logTypeColors[logType] || 'white';  // Standardfarbe ist Weiß
  };
  