import React from "react";
import { Typography } from "@mui/material";
import { Line } from "react-chartjs-2"; // Import the Line chart component from react-chartjs-2
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const BalanceChart = ({ balanceData }) => {
  // Null check for balanceData
  if (!balanceData || balanceData.length === 0) {
    return (
      <Typography variant="h6" color="white" gutterBottom style={{ marginTop: "1%" }}>
        No data available
      </Typography>
    );
  }

  const data = {
    labels: balanceData.map((dataPoint) => dataPoint.timestamp), // X-axis labels
    datasets: [
      {
        label: "Total Balance", // Label for the graph
        data: balanceData.map((dataPoint) => dataPoint.total), // Y-axis data
        fill: false,
        borderColor: "rgb(75, 192, 192)", // Line color
        tension: 0.25, // Smooth curve
      },
    ],
  };

  // Chart options for formatting Y-axis as USD
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return "$" + value.toFixed(2); // Format Y-axis values as USD
          },
        },
      },
    },
  };

  return (
    <div>
        <Line data={data} options={options} />
    </div>
  );
};

export default BalanceChart
